import { defineStore } from "pinia";

const runtimeConfig = useRuntimeConfig();

export const useAuth = defineStore("auth", {
  state: () => ({
    token: useCookie("token", {
      sameSite: "strict",
      domain: window.location.host.split(".").slice(-2).join("."),
    }),
    profile: null,

    initialized: false,
  }),

  actions: {
    async initialize() {
      try {
        await this.refresh();

        setInterval(() => this.refresh(), 10 * 60 * 1000);

        const responses = await Promise.all([
          $fetch(runtimeConfig.public.apiUrl + "/api/v1/auth/profile", {
            headers: { Authorization: "Bearer " + this.token },
          }),
        ]);

        this.profile = responses[0].data;

        this.initialized = true;

        return true;
      } catch (error) {
        return false;
      }
    },

    async refresh() {
      const response = await $fetch(
        runtimeConfig.public.apiUrl + "/api/v1/auth/tokens/refresh",
        { method: "POST", headers: { Authorization: "Bearer " + this.token } },
      );

      this.token = response.data.attributes.value;
    },

    logout() {
      this.token = null;

      navigateTo(runtimeConfig.public.authDomain, { external: true });
    },
  },
});
